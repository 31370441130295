<template>
  <ion-page class="page scrollable bg-transparent">
    <div class="d-flex justify-content-between align-items-center flex-wrap py-3">
      <div class="title">My Creations</div>

      <ion-button @click="action" class="cta">
        <i class="ti-plus mr-1" />
        {{ buttonContent }}</ion-button
      >
    </div>
    <CreationsTabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
    <div class="d-flex align-items-center">
      <ion-icon class="sort-icon" :icon="optionsOutline" />
      <multiselect
        v-model="selectedSort"
        class="choose-options sort-filter-select mx-1"
        :options="sortOptions"
        select-label=""
        selected-label=""
        deselect-label=""
        :multiple="false"
        :taggable="false"
        open-direction="below"
        :close-on-select="true"
        :searchable="false"
        track-by="value"
        label="name"
        :allow-empty="false"
        @update:modelValue="(value:any) =>  selection(value)"
        :class="{ 'w-100': isLessThan570px }"
      >
        <template #noOptions></template>
      </multiselect>
      <SearchInput
        v-model="text"
        :search-icon="true"
        :shortcut-icon="false"
        :clear-icon="false"
        :hide-shortcut-icon-on-blur="true"
        :clear-on-esc="false"
        :blur-on-esc="true"
        :wrapper-class="'search-input-wrapper'"
        :select-on-focus="false"
        :shortcut-listener-enabled="true"
        placeholder="Aa"
        @input="onSearch"
      />
    </div>
    <div class="count mt-3 mb-1" v-if="!loading">
      <span v-if="currentTab === 'characters'">Characters ({{ allCharacters.length }})</span>
      <span v-if="currentTab === 'folders'">Folders ({{ foldersCount }})</span>
      <span v-if="currentTab === 'worlds'">Worlds ({{ allWorldsCount }})</span>
      <span v-if="currentTab === 'bookmarks'">Bookmarks ({{ bookmarksCount }})</span>
    </div>
    <InfiniteCharacters
      v-if="currentTab === 'characters'"
      layoutChanger
      class="my-2"
      :hideTypeTag="true"
      :characters="allCharacters"
      :paging="allCharactersPaging"
      :loading="loading"
      :loadingMore="loadingMore"
      @load="requestLoadMore"
    />
    <InfiniteCollections
      v-else-if="currentTab === 'folders'"
      class="my-2"
      :folders="folders"
      :paging="paging"
      :loading="loading"
      @load="requestLoadMore"
      :loadingMore="loadingMore"
    />
    <InfiniteWorlds
      v-else-if="currentTab === 'worlds'"
      class="my-2"
      :worlds="allWorlds"
      :paging="allWorldsPaging"
      :loading="loading"
      @load="requestLoadMore"
      :loadingMore="loadingMore"
    />
    <InfiniteBookmarkCollections
      v-else-if="currentTab === 'bookmarks'"
      class="my-2"
      :bookmarkCollections="bookmarkCollections"
      :paging="paging"
      :loading="loading"
      @load="requestLoadMore"
      :loadingMore="loadingMore"
    />
  </ion-page>
</template>

<script lang="ts" setup>
import CreationsTabs from './CreationsTabs.vue';
import Multiselect from 'vue-multiselect';
import SearchInput from 'vue-search-input';
import InfiniteCharacters from '@/shared/components/InfiniteCharacters.vue';
import InfiniteBookmarkCollections from '@/shared/components/InfiniteBookmarkCollections.vue';
import InfiniteWorlds from '@/shared/components/InfiniteWorlds.vue';
import InfiniteCollections from '@/shared/components/InfiniteCollections.vue';
import { myCreationsTabs } from '@/shared/statics/tabs';
import { getCharactersDraft, getOwnCharacters } from '@/shared/actions/characters';
import { Character, Paging, Tab, WorldsInfo } from '@/shared/types/static-types';
import { getCharacterCollections } from '@/shared/actions/collections';
import { getBookmarkCollections } from '@/shared/actions/bookmarks';
import { authStore } from '@/shared/pinia-store/auth';
import { getUserStoryWorld, getWorldDrafts } from '@/shared/actions/worlds';
import { getNextPage } from '@/shared/helpers/pagination';
import { optionsOutline } from 'ionicons/icons';
import { useWindowSize } from '../../../composables/useWindowSize';
const tabs = myCreationsTabs;
const currentTab = ref('characters');
const tabIndex = ref(0);
const characters = ref([]);
const charactersCount = ref(0);
const worldsCount = ref(0);
const foldersCount = ref(0);
const bookmarksCount = ref(0);
const draftCharactersCount = ref(0);
const draftWorldsCount = ref(0);
const text = ref('');
const worlds = ref([]);
const characterDrafts = ref([]);
const worldDrafts = ref([]);
const folders = ref([]);
const bookmarkCollections = ref([]);
const paging = ref<Paging | null>(null);
const draftCharactersPaging = ref<Paging | null>(null);
const draftWorldsPaging = ref<Paging | null>(null);
const loading = ref(true);
const router = useRouter();
const loadingMore = ref(false);
const route = useRoute();

const tabName = computed(() => {
  return get(route.query, 'tab') as any;
});
const selectedSort: any = ref({
  name: tabName.value.charAt(0).toUpperCase() + tabName.value.slice(1),
  value: tabName.value,
});
const { user } = authStore();
const { isLessThan570px } = useWindowSize();
const sortOptions: any = [
  { name: 'Characters', value: 'characters' },
  { name: 'Worlds', value: 'worlds' },
  { name: 'Folders', value: 'folders' },
  { name: 'Bookmarks', value: 'bookmarks' },
];
const buttonContent = computed(() => `Create ${currentTab.value.substring(0, currentTab.value.length - 1)}`);

const action = () => {
  const router = useRouter();
  const route =
    currentTab.value === 'worlds'
      ? { name: 'create-story-world' }
      : currentTab.value === 'characters'
      ? { name: 'character-creator' }
      : currentTab.value === 'folders'
      ? { name: 'create-collection' }
      : { name: 'create-bookmark-collection' };
  router.push(route);
};

const selection = ({ value }: Tab) => {
  text.value = '';
  router.replace({ name: 'my-creations', query: { tab: value } });
  const tIndex = tabs.findIndex((tab) => tab.value === value);
  if (tIndex !== -1) {
    tabIndex.value = tIndex;
    currentTab.value = value.toLowerCase();
    requestLoadInit();
  }
};

const tabChanged = ({ value }: Tab) => {
  text.value = '';
  currentTab.value = value;
  router.replace({ name: 'my-creations', query: { tab: value } });
  selectedSort.value = { name: value.charAt(0).toUpperCase() + value.slice(1), value: value };
  requestLoadInit();
};

const fetchCharacters = async (page = 1) => {
  try {
    loading.value = true;
    const { results, ...p } = isEmpty(text.value)
      ? await getOwnCharacters(page, 10)
      : await getOwnCharacters(page, 10, { search: text.value });
    charactersCount.value = p.count;
    characters.value = results;
    paging.value = p;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const fetchWorlds = async (page = 1) => {
  try {
    loading.value = true;
    const { results, ...p } = isEmpty(text.value)
      ? await getUserStoryWorld(user.value.id, 'story', page, 10, null, { own: true })
      : await getUserStoryWorld(user.value.id, 'story', page, 10, null, { own: true, search: text.value });
    worldsCount.value = p.count;
    worlds.value = results;
    paging.value = p;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const fetchFolders = async (page = 1) => {
  try {
    loading.value = true;
    const { results, ...p } = isEmpty(text.value)
      ? await getCharacterCollections(user.value.id, 1)
      : await getCharacterCollections(user.value.id, 1, { search: text.value });
    foldersCount.value = p.count;
    folders.value = results;
    paging.value = p;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const fetchBookmarks = async (page = 1) => {
  try {
    loading.value = true;
    const { results, ...p } = isEmpty(text.value)
      ? await getBookmarkCollections(page)
      : await getBookmarkCollections(page, null, { search: text.value });
    bookmarksCount.value = p.count;
    bookmarkCollections.value = results;
    paging.value = p;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const fetchCharactersDraft = async (page = 1) => {
  try {
    loading.value = true;
    const { results, ...p } = isEmpty(text.value)
      ? await getCharactersDraft('draft', page)
      : await getCharactersDraft('draft', page, 10, { search: text.value });
    characterDrafts.value = results;
    draftCharactersCount.value = p.count;
    draftCharactersPaging.value = p;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const allCharacters = computed(() => {
  return [...characterDrafts.value, ...characters.value].sort(
    (a: Character, b: Character) => new Date(b.created).valueOf() - new Date(a.created).valueOf()
  );
});

const allWorlds = computed(() => {
  return [...worldDrafts.value, ...worlds.value].sort(
    (a: WorldsInfo, b: WorldsInfo) => new Date(b.created).valueOf() - new Date(a.created).valueOf()
  );
});

const allWorldsCount = computed(() => {
  return (draftWorldsPaging.value?.count || 0) + (paging.value?.count || 0);
});

const allCharactersPaging = computed(() => {
  return {
    next: draftCharactersPaging.value?.next || paging.value?.next,
  } as Paging;
});

const allWorldsPaging = computed(
  () =>
    ({
      next: draftWorldsPaging.value?.next || paging.value?.next,
    } as Paging)
);

const fetchAllCharacters = async () => {
  try {
    loading.value = true;

    await fetchCharactersDraft();
    await fetchCharacters();
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const fetchAllWorlds = async () => {
  try {
    await Promise.all([fetchWorldDrafts(), fetchWorlds()]);
    loading.value = true;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const fetchWorldDrafts = async (page = 1) => {
  try {
    loading.value = true;
    const { results, ...p } = isEmpty(text.value)
      ? await getWorldDrafts('story', page)
      : await getWorldDrafts('story', page, 10, { search: text.value });
    draftWorldsCount.value = p.count;
    worldDrafts.value = results;
    draftWorldsPaging.value = p;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const requestLoadInit = async () => {
  if (!currentTab.value) currentTab.value = 'characters';
  if (currentTab.value === 'characters') await fetchAllCharacters();
  else if (currentTab.value === 'worlds') await fetchAllWorlds();
  else if (currentTab.value === 'folders') await fetchFolders();
  else if (currentTab.value === 'bookmarks') await fetchBookmarks();
};

const searchTextChanged = debounce(async () => {
  if (currentTab.value === 'characters') fetchAllCharacters();
  else if (currentTab.value === 'worlds') fetchAllWorlds();
  else if (currentTab.value === 'folders') fetchFolders();
  else if (currentTab.value === 'bookmarks') fetchBookmarks();
}, 500);

const onSearch = () => {
  searchTextChanged();
};

const requestLoadMore = async () => {
  try {
    loadingMore.value = true;
    if (currentTab.value === 'worlds') {
      const { results: wResults, ...p } = await getNextPage(paging.value!);
      const { results: wDResults, ...pd } = await getNextPage(draftWorldsPaging.value!);
      paging.value = p;
      draftWorldsPaging.value = pd;
      worlds.value = worlds.value.concat(wResults);
      worldDrafts.value = worldDrafts.value.concat(wDResults);
    }
    if (currentTab.value === 'characters') {
      const { results: chResults, ...p } = await getNextPage(paging.value!);
      const { results: chDResults, ...pd } = await getNextPage(draftCharactersPaging.value!);
      paging.value = p;
      draftCharactersPaging.value = pd;
      characters.value = characters.value.concat(chResults);
      characterDrafts.value = characterDrafts.value.concat(chDResults);
    }
    if (currentTab.value === 'folders') {
      const { results, ...p } = await getNextPage(paging.value!);
      folders.value = folders.value.concat(results);
      paging.value = p;
    }
  } catch (e) {
  } finally {
    loadingMore.value = false;
  }
};

onMounted(() => {
  const tIndex = tabs.findIndex((tab) => tab.value === route.query.tab);
  if (route.query.tab && tIndex !== -1) {
    tabIndex.value = tIndex;
    currentTab.value = String(route.query.tab);
    requestLoadInit();
  } else {
    fetchCharacters();
  }
});

onBeforeRouteUpdate((to) => {
  const newTab = String(to.query.tab);

  if (newTab && currentTab.value !== newTab) {
    currentTab.value = newTab;
    tabIndex.value = indexOf(
      tabs,
      find(tabs, (tab) => tab.value === newTab)
    );
    if (newTab && tabIndex.value !== -1) {
      if (currentTab.value === 'characters') fetchAllCharacters();
      else if (currentTab.value === 'worlds') fetchAllWorlds();
      else if (currentTab.value === 'folders') fetchFolders();
      else if (currentTab.value === 'bookmarks') fetchBookmarks();
    } else {
      fetchAllCharacters();
    }
  }
});

watch(tabName, () => {
  if (tabName.value) {
    selectedSort.value = {
      name: tabName.value.charAt(0).toUpperCase() + tabName.value.slice(1),
      value: tabName.value,
    };
  }
});
</script>

<style lang="sass" scoped>
::v-deep .search-input-wrapper .search-icon
  color: #7A7E9B !important
::v-deep .search-input-wrapper .search-icon.search
    bottom: 9px !important
    width: 15px !important
    height: 15px !important
    border: 2px solid #7A7E9B !important
    &::after
      height: 5px !important
      background: #7A7E9B !important
      top: 10px !important
      left: 11px !important
::v-deep .search-input-wrapper input[data-search-input='true']
  border: 2px solid #C6C8D6 !important
  border-radius: 20px !important
  height: 31px !important

.choose-options
    width: 180px
.sort-icon
  font-size: 24px !important
  color: #7050B7
  @media(max-width:576px)
    font-size: 48px !important
.count
  font-size: 20px
  font-weight: bold
.cta
  border-radius: 10px
.title
  font-size: 24px
  font-weight: bold
  margin-left: 0.5rem

.multiselect
  min-height: unset

:deep
  .multiselect--active .multiselect__select
    transform: rotateZ(0deg) !important
    border-bottom-right-radius: 0px !important

  .multiselect--active .multiselect__tags
    border-bottom-left-radius: 0px !important
    border-bottom-right-radius: 0px !important


:deep(.multiselect--disabled)
  background: unset !important


:deep(.multiselect)
  .multiselect__tags
    border-radius: 12px
    border: 3px solid  #7050B7
    height: 30px
    min-height: 30px !important
    padding: 3px 40px 0 8px

  .multiselect__content-wrapper
    border-radius: 15px !important
    border-top-left-radius: 0px !important
    border-top-right-radius: 0px !important

  .multiselect__select
    height: 29px !important
    background: #7050B7
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
    right: 0px
  .multiselect__select::before
    border-color: #fff transparent transparent transparent !important
  .multiselect__single
    line-height: 18px !important
    width: 120px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  .multiselect__placeholder
    padding-top: 0px
  .multiselect__spinner
    height: 29px !important
    background:  #7050B7
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
  .multiselect__spinner:before, .multiselect__spinner:after
    border-color: #ffffff transparent transparent !important

:deep(.sort-filter-select)
  .multiselect__single
    font-size: 14px !important
  .multiselect__tags
    border: 3px solid #7050B7 !important

  .multiselect__select
    background: #7050B7 !important
  .multiselect__spinner
    background: #7050B7 !important
</style>
